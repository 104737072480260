import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import WebsiteLogo from "../images/bauschmiede_logo.png";

const Header = ({siteTitle, serviceMenu}) => (
    <header>
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Link to={`/`} className="navbar-item">
                        <img src={WebsiteLogo} alt={siteTitle}/>
                    </Link>

                    <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false"
                       data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                        <Link to={`/`} className="navbar-item">
                            Startseite
                        </Link>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link">
                                Leistungen
                            </div>

                            <div className="navbar-dropdown">
                                {serviceMenu.map((value, index) => (
                                    <Link key={index} to={value.slug} className="navbar-item">
                                        {value.pageTitle}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
