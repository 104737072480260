/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import HandwerkLogo from "../images/handwerk.jpg";

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      footerMenu: allContentfulPage(sort: {fields: pageTitle, order: ASC}, filter: {footerNavigation: {eq: true}}) {
        nodes {
          id
          slug
          pageTitle
        }
      }
      serviceMenu: allContentfulPage(sort: {fields: pageTitle, order: ASC}, filter: {serviceNavigation: {eq: true}}) {
          nodes {
              id
              slug
              pageTitle
          }
      }

    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} serviceMenu={data.serviceMenu.nodes}/>
      <main>{children}</main>
      <footer className="footer">
          <div className="container">

              <div className="columns">
                  <div className="column">
                      <div className="content">
                          <p>
                              <strong>Bauschmiede Köln</strong><br/>
                              <small>Inh. Josef Krüper</small><br />
                              Moltkestraße 87-89<br/>
                              50674 Köln<br/>
                          </p>
                      </div>
                  </div>
                  <div className="column">
                      <div className="content">
                          <p>
                              <strong>Kontakt</strong><br/>
                              Mobil: <a href="tel:+491578/4043652">01578/4043652</a><br/>
                              Tel: <a href="tel:+49221/519639">0221/519639</a><br/>
                              Fax: 0221/5102371<br/>
                          </p>
                          <p>
                              E-Mail: bauschmiede@gmail.com
                          </p>
                      </div>
                  </div>
                  <div className="column">
                      <div className="content">
                          <img src={HandwerkLogo} alt="Das Handwerk Logo" />
                          <ul>
                          {data.footerMenu.nodes.map((value, index) => (
                              <li>
                                  <Link key={index} to={value.slug}>{value.pageTitle}</Link>
                              </li>
                          ))}
                          </ul>

                      </div>
                  </div>
              </div>

              <div className="columns">
                  <div className="column">
                      &copy; 1998-2019 Bauschmiede Köln
                  </div>
              </div>

          </div>

      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
